import React from 'react';

// Assets
import RooftopImage from '../../images/rooftop.png';

const Rooftop = () => (
  <div className="c-rooftop">
    <figure className="c-rooftop__tiles">
      <img src={RooftopImage} alt="Old El Paso Roof" />
    </figure>
  </div>
);

export default Rooftop;

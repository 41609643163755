import React, { useContext } from 'react';

// Context
import SpinnaContext from '../contexts/SpinnaContext';

// Components
import Modal from '../components/Modal';
import Button from '../components/Button';

const AnnouncementModal = () => {
  const context = useContext(SpinnaContext);
  const { task, spinner, announcement } = context;

  return (
    <Modal
      title="Best Fajita Friday ever?"
      isOpen={!spinner.state && !task.state && announcement.state}
      isOpenHandler={announcement.handler}
    >
      <p className="u-body-lg">Share your best pics using</p>
      <h3 className="u-text-color-secondary">#oldelpasofajitafriday</h3>
      <p className="u-body-lg">
        and tag us at @OldElPasoUK on{' '}
        <a
          href="https://www.instagram.com/oldelpasouk/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Instagram
        </a>{' '}
        and{' '}
        <a
          href="https://www.facebook.com/oldelpasouk/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Facebook
        </a>
        .
      </p>
      <h5 className="u-text-color-secondary">
        Come back next week to make some noise for Fajita Friday!
      </h5>
      <Button
        variant="solid"
        href="https://www.facebook.com/oldelpasouk/"
        target="_blank"
      >
        Follow us online!
      </Button>
    </Modal>
  );
};

export default AnnouncementModal;

import { css } from 'styled-components';

export const BREAKPOINTS = {
  mobile: 576,
  tablet: 768,
  desktop: 1025,
  wide: 1440,
  fullbleed: 1920,
};

export const RADIUS = {
  mobile: 275,
  tablet: 420,
  desktop: 260,
  // wide: 320,
};

export const COLORS = {
  white: '#ffffff',
  red: '#d12128',
  yellow: '#fdc022',
};

const pixelsToEms = size => `${size / 16}em`;

// iterate through the sizes and create a media template
// https://www.styled-components.com/docs/advanced#media-templates
export const minWidth = Object.keys(BREAKPOINTS).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${pixelsToEms(BREAKPOINTS[label])}) {
      ${css(...args)}
    }
  `;

  return acc;
}, {});

export const maxWidth = Object.keys(BREAKPOINTS).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${pixelsToEms(BREAKPOINTS[label] - 1)}) {
      ${css(...args)}
    }
  `;

  return acc;
}, {});

export const minWidthQuery = Object.keys(BREAKPOINTS).reduce((acc, label) => {
  acc[label] = () => `(min-width: ${pixelsToEms(BREAKPOINTS[label])})`;

  return acc;
}, {});

export const maxWidthQuery = Object.keys(BREAKPOINTS).reduce((acc, label) => {
  acc[label] = () => `(max-width: ${pixelsToEms(BREAKPOINTS[label] - 1)})`;

  return acc;
}, {});

// module.exports = {
//   BREAKPOINTS,
//   RADIUS,
//   COLORS,
//   minWidth,
//   maxWidth,
//   minWidthQuery,
//   maxWidthQuery,
// };

import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useContext,
} from 'react';
import { useMediaQuery } from 'react-responsive';
import { useDrag } from 'react-use-gesture';
import useSound from 'use-sound';

// Config
import { BREAKPOINTS, RADIUS, COLORS } from '../config';

// Audio
import tickSfx from '../../audio/tick.m4a';

// Context
import SpinnaContext from '../contexts/SpinnaContext';

// Data
import Challenges from '../../data/challenges.json';

// Function to convert degrees to radian
const deg2rad = deg => (deg * Math.PI) / 180;

// Function to wrap text
const wrapText = (context, text, x, y, maxWidth, lineHeight) => {
  const words = text.split(' ');
  let line = '';
  let yy = y;

  words.forEach((word, key) => {
    const testLine = `${line + words[key]} `;
    const metrics = context.measureText(testLine);
    const testWidth = metrics.width;

    if (testWidth > maxWidth && key > 0) {
      context.fillText(line, x, yy);
      line = `${words[key]} `;
      yy += lineHeight;
    } else {
      line = testLine; // can fit longer text
    }
  });

  context.fillText(line, x, yy);
};

// Function to render chillis
const wrapChilli = (context, state, y, w, h) => {
  const img = document.getElementById('chilli');

  const width = w;
  const height = h;

  switch (state) {
    case 1:
      context.drawImage(img, -width / 2, y, width, height);
      break;
    case 2:
      context.drawImage(img, -width + width / 4, y, width, height);
      context.drawImage(img, width / 2 - width / 4, y, width, height);
      break;
    default:
      context.drawImage(img, -width / 2 - width, y, width, height);
      context.drawImage(img, -width / 2, y, width, height);
      context.drawImage(img, -width / 2 + width, y, width, height);
      break;
  }
};

// Function to generate number between min and max
const rand = (min, max) => Math.random() * (max - min) + min;

const SpinnerWheel = () => {
  // React hooks
  const ref = useRef(null);

  const context = useContext(SpinnaContext);
  const { task, spinner } = context;

  const [initFlag, setInitFlag] = useState(true);
  const [ready, setReady] = useState(false);

  const [play] = useSound(tickSfx, {
    volume: spinner.volume,
    interrupt: true,
  });

  // Media Queries
  const isTablet = useMediaQuery({
    query: `(min-width: ${BREAKPOINTS.tablet}px)`,
  });
  const isDesktop = useMediaQuery({
    query: `(min-width: ${BREAKPOINTS.desktop}px)`,
  });
  // const isWide = useMediaQuery({
  //   query: `(min-width: ${BREAKPOINTS.wide}px)`,
  // });

  let dimension = RADIUS.mobile * 2 + 40;
  let font = '22px Old El Paso';
  let fontHeight = 25;
  let chilliWidth = 16;
  let chilliHeight = 30;

  // if (isWide) {
  //   dimension = RADIUS.wide * 2 + 40;
  //   font = '28px Old El Paso';
  //   fontHeight = 30;
  //   chilliWidth = 16;
  //   chilliHeight = 30;
  // } else
  if (isDesktop) {
    dimension = RADIUS.desktop * 2 + 40;
    font = '21px Old El Paso';
    fontHeight = 23;
    chilliWidth = 16;
    chilliHeight = 30;
  } else if (isTablet) {
    dimension = RADIUS.tablet * 2 + 40;
    font = '36px Old El Paso';
    fontHeight = 36;
    chilliWidth = 22;
    chilliHeight = 42;
  }

  // Variables
  const label = Challenges.map(challenge => challenge.name);
  const level = Challenges.map(challenge => challenge.level);
  const colors = [
    COLORS.red,
    COLORS.yellow,
    COLORS.white,
    COLORS.red,
    COLORS.yellow,
    COLORS.white,
    COLORS.red,
    COLORS.yellow,
    COLORS.white,
  ];
  const slices = label.length;
  const sliceDegree = 360 / slices;
  let speed = 5;
  let slowDownRand = 0;
  let onceFlag = false;
  let startDegree = rand(0, 360); // random start position

  // Draws the black borders of spinner wheel
  const drawCircle = color => {
    const ctx = ref.current.getContext('2d');
    const width = ref.current.width - 12 - 40;
    const radius = width / 2;
    const center = width / 2 + 6 + 20;

    ctx.beginPath();
    ctx.arc(center, center, radius, 0, deg2rad(360));
    ctx.strokeStyle = color;
    ctx.lineWidth = 12;
    ctx.stroke();
  };

  // Draws color slices of each challenges
  const drawSlice = (deg, color) => {
    const ctx = ref.current.getContext('2d');
    const width = ref.current.width - 12 - 40;
    const radius = width / 2;
    const center = width / 2 + 6 + 20;

    ctx.beginPath();
    ctx.moveTo(center, center);
    ctx.arc(
      center,
      center,
      radius,
      deg2rad(deg),
      deg2rad(deg + 360 / label.length)
    );
    ctx.lineTo(center, center);
    ctx.fillStyle = color;
    ctx.fill();
  };

  // Draws text on each slices
  const drawText = (deg, text) => {
    const ctx = ref.current.getContext('2d');
    const width = ref.current.width - 12 - 40;
    const radius = width / 2;
    const center = width / 2 + 6 + 20;
    const degSteps = 360 / label.length;

    ctx.save();
    ctx.translate(center, center);
    ctx.rotate(deg2rad(deg + degSteps / 2 + 90));
    ctx.textAlign = 'center';
    ctx.fillStyle = '#000';
    ctx.font = font;

    wrapText(
      ctx,
      text,
      0,
      -0.95 *
        radius *
        Math.cos(deg2rad(degSteps / 2)) *
        Math.cos(deg2rad(degSteps / 2)),
      radius * Math.sin(deg2rad(degSteps / 2)) * 2 - 80,
      fontHeight
    );
    ctx.restore();
  };

  // Draws chilli on each slices
  const drawChilli = (deg, lvl) => {
    const ctx = ref.current.getContext('2d');
    const width = ref.current.width - 12 - 40;
    const radius = width / 2;
    const center = width / 2 + 6 + 20;
    const degSteps = 360 / label.length;

    ctx.save();
    ctx.translate(center, center);
    ctx.rotate(deg2rad(deg + degSteps / 2 + 90));

    wrapChilli(
      ctx,
      lvl,
      -0.43 *
        radius *
        Math.cos(deg2rad(degSteps / 2)) *
        Math.cos(deg2rad(degSteps / 2)),
      chilliWidth,
      chilliHeight
    );
    ctx.restore();
  };

  const drawIndicator = () => {
    const ctx = ref.current.getContext('2d');
    const width = ref.current.width - 12 - 40;
    const center = width / 2 + 6 + 20;

    ctx.beginPath();
    ctx.moveTo(center, 48);
    ctx.arc(center, 16, 12, deg2rad(180), deg2rad(0));
    ctx.lineTo(center, 48);
    ctx.lineWidth = 2;
    ctx.strokeStyle = '#FAD32E';
    ctx.fillStyle = '#cb151c';
    ctx.stroke();
    ctx.fill();
  };

  const drawWheel = useCallback(() => {
    if (ref.current) {
      const ctx = ref.current.getContext('2d');
      const width = ref.current.width - 24 - 40;
      ctx.clearRect(0, 0, width, width);

      label.forEach((l, key) => {
        drawSlice(startDegree, colors[key]);
        drawText(startDegree, label[key]);
        drawChilli(startDegree, level[key]);
        startDegree += sliceDegree;
      });

      drawCircle('#000');
      drawIndicator();
    }
  });

  useEffect(() => {
    if (initFlag && ready) {
      drawWheel();
      window.addEventListener('resize', drawWheel);
      setInitFlag(false);
    }
  }, [drawWheel, initFlag, ready]);

  useEffect(() => {
    if (spinner.state) {
      let isStopped = false;
      let startT;
      let tickIndex;
      const anim = time => {
        if (startT === undefined) {
          startT = time;
        }

        const elapsed = time - startT;

        isStopped = elapsed / 1000 >= 1.5;
        startDegree += speed;
        startDegree %= 360; // reset if over 360

        if (tickIndex === undefined) {
          tickIndex = Math.floor(
            ((360 - startDegree - 90) % 360) / sliceDegree
          );
        }

        if (
          tickIndex !==
          Math.floor(((360 - startDegree - 90) % 360) / sliceDegree)
        ) {
          tickIndex = Math.floor(
            ((360 - startDegree - 90) % 360) / sliceDegree
          );
          play();
        }

        // Increment speed
        if (speed < 10 && !isStopped) {
          // console.log('increment');
          speed += 1 * 0.2;
        }

        // Decrement speed after 5 seconds
        if (isStopped) {
          if (!onceFlag) {
            onceFlag = true;
            slowDownRand = rand(0.985, 0.99);
          }
          // console.log('decrement');
          speed = speed > 0.2 ? (speed *= slowDownRand) : 0;
        }

        // Stopped
        if (onceFlag && !speed) {
          let ai = tickIndex; // deg 2 Array Index
          ai = (slices + ai) % slices; // Fix negative index

          // Set start back to false and save
          onceFlag = false;
          task.indexHandler(ai);

          setTimeout(() => {
            spinner.handler(false);
            task.handler(true);
          }, 500);

          return; // Get Array Item from end Degree
        }

        drawWheel();
        window.requestAnimationFrame(anim);
      };

      window.requestAnimationFrame(anim);
    }
  }, [spinner.state]);

  useEffect(() => {
    document.fonts.ready.then(() => {
      setReady(true);
    });
  }, []);

  const bind = useDrag(({ down, movement: [mx] }) => {
    // https://use-gesture.netlify.app/docs/state
    // down = true when a mouse button or touch is down
    // movement = last gesture offset (xy - initial)

    if (down && mx > 100 && !spinner.state) {
      spinner.handler(true);
      spinner.numberHandler(spinner.number + 1);
    }
  });

  return (
    <>
      {ready && (
        <canvas {...bind()} ref={ref} width={dimension} height={dimension} />
      )}
    </>
  );
};

export default SpinnerWheel;

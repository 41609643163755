import React from 'react';

// Components
import Rooftop from '../components/Rooftop';

const SiteHeaderSimple = () => (
  <div className="c-site-header c-site-header--fixed">
    <Rooftop />
  </div>
);

export default SiteHeaderSimple;

import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

// Components
import Button from './Button';
import Sprite from './Sprite';
import { ReactComponent as CloseSVG } from '../../icons/icon-close.svg';

const Modal = ({ isOpen, isOpenHandler, title, children, className }) => {
  const classes = cn('c-modal', 't-dark', isOpen && 'is-open', className);

  return (
    <div className={classes}>
      <div className="c-modal__wrapper">
        <div className="o-wrapper c-modal__body">
          <Button
            variant="outlined"
            isSmall
            onClick={() => {
              isOpenHandler(false);
            }}
          >
            Close
            <Sprite id="icon-close">
              <CloseSVG />
            </Sprite>
          </Button>
          {title && <h3 className="c-modal__title">{title}</h3>}
          <div className="content c-modal__content">{children}</div>
        </div>
      </div>
    </div>
  );
};

Modal.defaultProps = {
  isOpen: false,
  title: '',
};

Modal.propTypes = {
  isOpen: PropTypes.bool,
  isOpenHandler: PropTypes.func.isRequired,
  title: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default Modal;

import React, { useContext } from 'react';

// Context
import SpinnaContext from '../contexts/SpinnaContext';

// Components
import Button from './Button';

// Asset
import ChilliLegend from '../../images/legend.svg';

const Legend = () => {
  const context = useContext(SpinnaContext);
  const { howToPlay } = context;

  return (
    <div className="c-legend">
      <div className="c-legend__button">
        <Button
          variant="ghost"
          isSmall
          onClick={() => {
            howToPlay.handler(true);
          }}
        >
          How to play
        </Button>
      </div>
      <div className="c-legend__description">
        <h6 className="u-text-align-right">
          The more chillies, the spicier your challenge!
        </h6>
        <figure className="c-legend__image">
          <img src={ChilliLegend} alt="Chilli Legend" />
        </figure>
      </div>
    </div>
  );
};

export default Legend;

import React, { useContext } from 'react';
import PropTypes from 'prop-types';

// Components
import Modal from '../components/Modal';
import Countdown from '../components/Countdown';

// Assets

// Context
import SpinnaContext from '../contexts/SpinnaContext';

const ChallengeModal = ({ challenge }) => {
  const context = useContext(SpinnaContext);
  const { task } = context;

  const { name, countdown } = challenge;

  return (
    <Modal
      title="Your challenge"
      isOpen={task.state}
      isOpenHandler={task.handler}
      className="c-modal--challenge"
    >
      <h3 className="u-text-align-center">{name}</h3>
      {countdown && <Countdown />}
    </Modal>
  );
};

ChallengeModal.propTypes = {
  challenge: PropTypes.shape({
    name: PropTypes.string,
    level: PropTypes.number,
    countdown: PropTypes.bool,
  }).isRequired,
};

export default ChallengeModal;

import React from 'react';

const SpinnaContext = React.createContext({
  howToPlay: {
    state: false,
    handler: () => {},
  },
});

export default SpinnaContext;

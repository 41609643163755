import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import cn from 'classnames';

// Config
import { COLORS } from '../config';

// Context
import SpinnaContext from '../contexts/SpinnaContext';

// Styled components
const AnimatedH1 = styled.h1`
  position: relative;
  display: inline-block;
  overflow: hidden;

  &::before {
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    content: '1...2...3';
    color: ${COLORS.yellow};
    transition: width 3000ms ease-in-out;
    width: 0;
    white-space: nowrap;
  }

  &.is-mounted {
    &::before {
      width: 100%;
    }
  }

  &.is-done {
    &::before {
      content: 'Go!';
    }
  }
`;

const Countdown = () => {
  const [timer, setTimer] = useState(0);
  const [mounted, setMounted] = useState(false);
  const context = useContext(SpinnaContext);
  const { task } = context;

  useEffect(() => {
    setTimeout(() => {
      if (mounted) {
        setTimer(timer + 1);
      } else {
        // reset
        setTimer(0);
      }
    }, 1000);
  }, [mounted, timer]);

  useEffect(() => {
    if (task.state) {
      if (!mounted) {
        setTimeout(() => {
          setMounted(true);
        }, 1000);
      }
    } else if (mounted) {
      setMounted(false);
    }
  }, [mounted, task.state]);

  const classes = cn(
    'u-text-align-center',
    mounted && 'is-mounted',
    timer >= 3 && 'is-done'
  );

  return (
    <>
      <AnimatedH1 className={classes}>
        {timer >= 3 ? 'Go!' : '1...2...3'}
      </AnimatedH1>
    </>
  );
};

export default Countdown;

/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cognito_identity_pool_id": "eu-west-2:5b3028c2-a1c2-4562-8ef2-762249e2fe10",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_uHhcI8Sni",
    "aws_user_pools_web_client_id": "4b3dmjubvf0kjp477ps0nt7dlt",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://dggdogu3yneh3cfh2nvoe6lqe4.appsync-api.eu-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-p3cge5yynrfjtprwhegp4qrtkm"
};


export default awsmobile;

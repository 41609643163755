import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

// Config
import { BREAKPOINTS, minWidth } from '../config';

// Components
import Button from '../components/Button';
import Sprite from '../components/Sprite';

// Assets
import OldElPasoLogo from '../../images/oldelpaso.png';
import DesktopIntro from '../../images/intro-desktop.svg';
import MobileIntro from '../../images/intro-mobile.svg';
import { ReactComponent as SplashLeftSVG } from '../../icons/icon-splash-left.svg';
import { ReactComponent as SplashRightSVG } from '../../icons/icon-splash-right.svg';

// Context
import SpinnaContext from '../contexts/SpinnaContext';

// Styled Components
const HomeWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const Logo = styled.figure`
  width: 100%;
  max-width: 33.33%;
  margin: 0 auto;
  margin-bottom: 16px;

  ${minWidth.tablet`
    max-width: 220px;
    margin-bottom: 72px;
  `}

  ${minWidth.desktop`
    max-width: 260px;
    margin-bottom: 40px;
  `}

  img {
    display: block;
    width: 100%;
    max-width: 100%;
    height: auto;
  }
`;

const Intro = styled.figure`
  @keyframes grow {
    0% {
      transform: scale(0.01);
    }

    90% {
      transform: scale(1.1);
    }

    100% {
      transform: scale(1);
    }
  }

  width: 100%;
  max-width: calc(100% - 80px);
  margin: 0 auto;
  margin-bottom: 24px;
  animation: grow 1.5s ease-in-out;
  animation-play-state: initial;

  ${minWidth.tablet`
    max-width: 700px;
    margin-bottom: 16px;
  `}

  ${minWidth.desktop`
    max-width: 760px;
    margin-bottom: 0;
  `}

  img {
    display: block;
    width: 100%;
    max-width: 100%;
    height: auto;
  }

  &.animate-in {
    animation-play-state: running;
  }
`;

const SplashLeft = styled(Sprite)`
  position: absolute;
  margin: 0;
  top: -32px;
  left: -50px;
  width: 50px;
  height: 50px;
  opacity: 0;
  transition: opacity 375ms ease-in-out 1s;

  svg {
    width: 50px;
    height: 50px;
  }

  &.animate-in {
    opacity: 1;
  }
`;

const SplashRight = styled(Sprite)`
  position: absolute;
  margin: 0;
  bottom: -32px;
  right: -39px;
  width: 39px;
  height: 55px;
  opacity: 0;
  transition: opacity 375ms ease-in-out 1.375s;

  svg {
    width: 39px;
    height: 55px;
  }

  &.animate-in {
    opacity: 1;
  }
`;

const ButtonGroup = styled.div`
  max-width: 40%;
  margin: 0 auto;

  ${minWidth.tablet`
    max-width: none;
  `}
`;

const Caption = styled.h6`
  text-align: center;
  margin-top: 32px;

  ${minWidth.tablet`
    margin-top: 56px;
  `}
`;

const Home = () => {
  const [init, setInit] = useState(false);
  const context = useContext(SpinnaContext);
  const { howToPlay } = context;

  useEffect(() => {
    setTimeout(() => {
      setInit(true);
    }, 250);
  }, []);

  // Media Queries
  const isTablet = useMediaQuery({
    query: `(min-width: ${BREAKPOINTS.tablet}px)`,
  });

  return (
    <HomeWrapper className="o-wrapper u-pr">
      <div>
        <Logo>
          <img src={OldElPasoLogo} alt="Make some noise with Old El Paso" />
        </Logo>
        <Intro className={init && 'animate-in'}>
          <img
            src={isTablet ? DesktopIntro : MobileIntro}
            alt="Fajita Friday Spinna"
          />
        </Intro>
        <ButtonGroup className="button-group">
          <Button as={Link} to="/spinner" variant="solid" className="u-pr">
            Let's play
            <SplashLeft id="icon-splash-left" className={init && 'animate-in'}>
              <SplashLeftSVG />
            </SplashLeft>
            <SplashRight
              id="icon-splash-right"
              className={init && 'animate-in'}
            >
              <SplashRightSVG />
            </SplashRight>
          </Button>{' '}
          <Button
            variant="outlined"
            onClick={() => {
              howToPlay.handler(true);
            }}
          >
            How to play
          </Button>
        </ButtonGroup>
        <Caption>
          Challenge your friends and family for a spin and make some noise this
          fajita friday
        </Caption>
      </div>
      
    </HomeWrapper>
  );
};

export default Home;

import React from 'react';
import styled from 'styled-components';

// Config
import { RADIUS, minWidth } from '../config';

// Components
import SpinnerWheel from '../components/SpinnerWheel';
import Sprite from '../components/Sprite';

// Asset
import { ReactComponent as SwipeSVG } from '../../icons/icon-swipe.svg';

// Styled Components
const SpinnerLayout = styled.div`
  position: absolute;
  top: ${RADIUS.mobile + 356}px;
  left: 50%;
  width: ${RADIUS.mobile * 2 + 40}px;
  height: ${RADIUS.mobile * 2 + 40}px;
  transform: translate(-50%, -50%);
  z-index: 1001;

  ${minWidth.tablet`
    top: 100%;
    width: ${RADIUS.tablet * 2 + 40}px;
    height: ${RADIUS.tablet * 2 + 40}px;
  `}

  ${minWidth.desktop`
    width: ${RADIUS.desktop * 2 + 40}px;
    height: ${RADIUS.desktop * 2 + 40}px;
    top: calc(50% - 16px);
  `}
`;

const SwipeInstruction = styled.div`
  position: absolute;
  top: -32px;
  left: 120px;

  ${minWidth.tablet`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    top: 128px;
    left: -32px;

    h5 {
      max-width: 100px;
      text-align: center;
    }
  `}

  ${minWidth.desktop`
    display: none;
  `}
`;

const SwipeSprite = styled(Sprite)`
  transform: rotate(8deg);
  margin-left: 16px;

  &,
  svg {
    width: 40px;
    height: 51px;
  }

  ${minWidth.tablet`
    transform: rotate(-16deg);
    margin-left: 0;

    &,
    svg {
      width: 48px;
      height: 61px;
    }
  `}
`;

const Spinner = () => (
  <>
    <SpinnerLayout>
      <SwipeInstruction>
        <h5>Swipe wheel to spin</h5>
        <SwipeSprite id="icon-swipe">
          <SwipeSVG />
        </SwipeSprite>
      </SwipeInstruction>
      <SpinnerWheel />
    </SpinnerLayout>
  </>
);

export default Spinner;

import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const Sprite = ({ id, className, children, color }) => {
  const classes = cn(`sprite -${id}`, color && `color-${color}`, className);

  return <span className={classes}>{children}</span>;
};

Sprite.defaultProps = {
  className: '',
};

Sprite.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  color: PropTypes.string,
};

export default Sprite;

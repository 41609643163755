import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

// Components
import Modal from '../components/Modal';
import Button from '../components/Button';

// Context
import SpinnaContext from '../contexts/SpinnaContext';

const HowToPlayModal = ({ match, location, history }) => {
  const context = useContext(SpinnaContext);
  const { howToPlay, spinner } = context;

  return (
    <Modal
      title="How to play"
      isOpen={howToPlay.state}
      isOpenHandler={howToPlay.handler}
    >
      <p className="u-text-align-left">
        Ready to play Fajita Friday Spinna and Make Some Noise? Here's how to do
        it:
      </p>
      <ol>
        <li>Prep the ingredients for your Fajita Friday.</li>
        <li>
          Gather around the table! You can even connect dinner tables virtually
          with other friends and family by sharing your screen.
        </li>
        <li>
          Choose a player to start and spin the Spinna! The more chillies you
          land on, the spicier the challenge.
        </li>
        <li>Complete the challenge!</li>
        <li>Next player spins the Spinna!</li>
        <li>End the game whenever all players are full.</li>
      </ol>
      <p className="u-text-align-left">
        Share your Fajita Friday pics on Instagram or Facebook using{' '}
        <strong>#OldElPasoFajitaFriday</strong> and tag us at{' '}
        <strong>@OldElPasoUK!</strong>
      </p>
      {!spinner.state && (
        <Button
          variant="solid"
          onClick={() => {
            howToPlay.handler(false);
            if (match.isExact) {
              // Homepage
              history.push('/spinner');
            }
          }}
        >
          Let's play!
        </Button>
      )}
    </Modal>
  );
};

HowToPlayModal.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(HowToPlayModal);

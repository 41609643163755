import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { API, graphqlOperation } from 'aws-amplify';
import { useMediaQuery } from 'react-responsive';
import useSound from 'use-sound';

import './stylesheets/app.scss';

// graphql
import { listTodos, getTodo } from './graphql/queries';
import { createTodo, updateTodo } from './graphql/mutations';
import { onUpdateTodo } from './graphql/subscriptions';

// Audio
import backgroundSfx from './audio/background_v2.mp3';
// import backgroundSfx from './audio/background.mp3';

// Config
import { minWidth } from './javascripts/config';

// Context
import SpinnaContext from './javascripts/contexts/SpinnaContext';

// Data
import Challenges from './data/challenges.json';

// Modules
import HowToPlayModal from './javascripts/modules/HowToPlayModal';
import ChallengeModal from './javascripts/modules/ChallengeModal';
import AnnouncementModal from './javascripts/modules/AnnouncementModal';
import SiteHeader from './javascripts/modules/SiteHeader';
import SiteHeaderSimple from './javascripts/modules/SiteHeaderSimple';
import SiteFooter from './javascripts/modules/SiteFooter';

// Page
import Home from './javascripts/pages/Home';
import Spinner from './javascripts/pages/Spinner';

// Assets
import MobileBG from './images/bg-mobile.svg';
import TabletBG from './images/bg-tablet.svg';
import DesktopBG from './images/bg-desktop.svg';
import Chilli from './images/chilli.svg';

// Styled Components
const AppContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center center;
  max-width: 100vw;
  background-image: url(${MobileBG});

  ${minWidth.tablet`
    background-image: url(${TabletBG});
  `}

  ${minWidth.desktop`
    background-image: url(${DesktopBG});
    max-width: 1440px;
    margin: 0 auto;
    overflow:auto;
  `}
`;

const MessageContainer = styled(AppContainer)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

function App() {
  const [volume, setVolume] = useState(1); // Controls the volume of useSounds
  const [counts, setCounts] = useState(0);
  const [howToPlay, setHowToPlay] = useState(false); // Controls state of <HowToPlayModal />
  const [task, setTask] = useState(false); // Controls state of <ChallengeModal />
  const [index, setIndex] = useState(0); // Controls state of <ChallengeModal />
  const [announcement, setAnnouncement] = useState(false);
  const [spin, setSpin] = useState(false); // Controls state of <SpinnerWheel />
  const [numberOfSpin, setNumberOfSpin] = useState(0); // Controls state of number of spins done

  const [play, { sound }] = useSound(backgroundSfx, {
    volume,
  });

  const isMobileAndLandscape = useMediaQuery({
    query: `(orientation: landscape) and (max-width: 812px)`,
  });

  useEffect(() => {
    if (numberOfSpin !== 0) {
      // local number of spin increased
      // update graphql
      const update = async id => {
        // const data = await API.graphql(graphqlOperation(getTodo, { id }));

        // const updateDetails = {
        //   id,
        //   total: 0,
        // };

        // await API.graphql(
        //   graphqlOperation(updateTodo, { input: updateDetails })
        // );
      };

      update('oldelpaso-spinna');
    }

    if (numberOfSpin === 4) {
      setAnnouncement(true);
    }
  }, [numberOfSpin]);

  // useEffect(() => {
  //   // check if counter of id oldelpaso-spinna exist
  //   // const fetchCounters = async () => {
  //   //   const data = await API.graphql(graphqlOperation(listTodos));

  //   //   const i = data.data.listTodos.items.findIndex(
  //   //     item => item.id === 'oldelpaso-spinna'
  //   //   );
  //   //   if (i !== -1) {
  //   //     // found a counter
  //   //     setCounts(data.data.listTodos.items[i].total);
  //   //   } else {
  //   //     // create a counter
  //   //     const createDetails = {
  //   //       id: 'oldelpaso-spinna',
  //   //       total: 0,
  //   //     };

  //   //     await API.graphql(
  //   //       graphqlOperation(createTodo, { input: createDetails })
  //   //     )
  //   //       .then(response => {
  //   //         // console.log(response);
  //   //         setCounts(0);
  //   //       })
  //   //       .catch(err => {
  //   //         console.log(err);
  //   //       });
  //   //   }
  //   };

    //fetchCounters();

  //   const subscriber = API.graphql(graphqlOperation(onUpdateTodo), {
  //     id: 'test',
  //   }).subscribe({
  //     next: data => {
  //       const {
  //         value: {
  //           data: {
  //             onUpdateTodo: { total },
  //           },
  //         },
  //       } = data;
  //       setCounts(total);
  //     },
  //   });

  //   return () => subscriber.unsubscribe();
  // }, []);

  useEffect(() => {
    if (sound && sound.playing() && !task) {
      sound.stop();
    }
  }, [sound, task, volume]);

  useEffect(() => {
    if (sound) {
      sound.on('fade', function() {
        sound.stop();
      });

      if (spin) {
        if (sound.playing()) {
          sound.stop();
        }

        sound
          .seek(0)
          .volume(volume)
          .play();
      } else if (sound.playing()) {
        sound.fade(volume, 0, 4000);
      }
    }
  }, [spin, sound, volume]);

  return (
    <SpinnaContext.Provider
      value={{
        howToPlay: {
          state: howToPlay,
          handler: setHowToPlay,
        },
        task: {
          state: task,
          handler: setTask,
          index,
          indexHandler: setIndex,
        },
        announcement: {
          state: announcement,
          handler: setAnnouncement,
        },
        spinner: {
          state: spin,
          handler: setSpin,
          number: numberOfSpin,
          numberHandler: setNumberOfSpin,
          total: counts,
          volume,
          volumeHandler: setVolume,
        },
      }}
    >
      {isMobileAndLandscape ? (
        <MessageContainer>
          <h3 className="u-text-align-center">
            Please rotate your mobile device
          </h3>
        </MessageContainer>
      ) : (
        <AppContainer>
          <img
            id="chilli"
            src={Chilli}
            alt="Chilli"
            className="u-hidden-visually"
          />
          <AnnouncementModal />
          <ChallengeModal challenge={Challenges[index]} />
          <Router>
            <HowToPlayModal />
            <Switch>
              <Route exact path="/">
                <SiteHeaderSimple />
                <Home />
              </Route>
              <Route path="/spinner">
                <SiteHeader />
                <Spinner />
                <SiteFooter />
              </Route>
              
            </Switch>
          </Router>
        </AppContainer>
      )}
    </SpinnaContext.Provider>
  );
}

export default App;

import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const Button = React.forwardRef(
  (
    {
      as,
      children,
      className,
      variant,
      type,
      isDisabled,
      isLoading,
      isSmall,
      ...props
    },
    ref
  ) => {
    const classes = cn(
      'button c-button',
      variant && `c-button-${variant}`,
      isLoading && 'is-loading',
      isSmall && 'small',
      className
    );

    if (ref) {
      props.ref = ref;
    }

    if (props.href) {
      return (
        <a {...props} className={classes}>
          {children}
        </a>
      );
    }
    props.type = type;

    const Component = as === 'Link' ? Link : as || 'button';

    return (
      <Component {...props} className={classes} disabled={isDisabled}>
        {children}
      </Component>
    );
  }
);

Button.defaultProps = {
  isDisabled: false,
  isLoading: false,
  isSmall: false,
  type: 'button',
  variant: 'solid',
  className: '',
};

Button.propTypes = {
  as: PropTypes.elementType,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  href: PropTypes.string,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  isSmall: PropTypes.bool,
  variant: PropTypes.oneOf(['solid', 'outlined', 'ghost', 'social', 'icon']),
  type: PropTypes.oneOf(['button', 'reset', 'submit']),
};

Button.displayName = 'Button';

export default Button;

import React, { useContext } from 'react';
import styled from 'styled-components';

// Context
import SpinnaContext from '../contexts/SpinnaContext';

// Component
import Legend from '../components/Legend';
import Button from '../components/Button';
import Sprite from '../components/Sprite';

// Assets
import { ReactComponent as VolumeOnSVG } from '../../icons/icon-volume-on.svg';
import { ReactComponent as VolumeOffSVG } from '../../icons/icon-volume-off.svg';
import { ReactComponent as SplashLeftSVG } from '../../icons/icon-splash-left.svg';
import { ReactComponent as SplashRightSVG } from '../../icons/icon-splash-right.svg';

// Styled components
const SplashLeft = styled(Sprite)`
  position: absolute;
  margin: 0;
  top: 50%;
  left: -50px;
  width: 50px;
  height: 50px;
  transform: translateY(-50%) rotate(-45deg);
  transition: opacity 200ms ease-in-out;

  svg {
    width: 50px;
    height: 50px;
  }

  .is-loading & {
    opacity: 0;
  }
`;

const SplashRight = styled(Sprite)`
  position: absolute;
  margin: 0;
  top: 50%;
  right: -39px;
  width: 39px;
  height: 55px;
  transform: translateY(-50%) rotate(-15deg);
  transition: opacity 200ms ease-in-out;

  svg {
    width: 39px;
    height: 55px;
  }

  .is-loading & {
    opacity: 0;
  }
`;

const SiteFooter = () => {
  const context = useContext(SpinnaContext);
  const { task, spinner } = context;

  return (
    <div className="u-hide u-show-block@desktop c-site-footer">
      <div className="o-wrapper c-site-footer__wrapper">
        <div className="c-site-footer__body">
          <div className="u-hide u-show-block@tablet c-site-footer__left">
            <Button
              className="c-site-footer__sound"
              variant="icon"
              onClick={() => spinner.volumeHandler(spinner.volume ? 0 : 1)}
            >
              {spinner.volume !== 0 ? (
                <Sprite id="icon-volume-on">
                  <VolumeOnSVG />
                </Sprite>
              ) : (
                <Sprite id="icon-volume-off">
                  <VolumeOffSVG />
                </Sprite>
              )}
            </Button>
            <p className="u-body-sm">
              Visit{' '}
              <a
                href="https://oldelpaso.co.uk"
                target="_blank"
                rel="noopener noreferrer"
              >
                oldelpaso.co.uk
              </a>{' '}
              to find more <br></br>Fajita Friday recipes and products!
            </p>
            <p className="u-copyright"><span>&copy; General Mills</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <a className='anchor' href="https://www.generalmills.com/en/Company/privacy-policies/privacy-policy-cookie-UK" target="_blank">Privacy Policy</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <a className='anchor' href="https://contactus.generalmills.com/?page=www.generalmills.co.uk&_ga=2.238789632.124113736.1612367139-969072383.1550170555" target="_blank">Contact Us</a><br/>
                              <a className='anchor' href="https://www.generalmills.com/en/Company/privacy-policies/privacy-policy-cookie-UK#CookieNotice" target="_blank">Cookie Notice</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <a className='anchor' href="https://www.generalmills.com/Company/legal-terms/termsofuse-ENUK" target="_blank">Terms of Use</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              
                              <a className='anchor optanon-show-settings'>Customise Cookie Settings</a><br/>
                              <a className='anchor' href="https://privacyportal.onetrust.com/webform/3fab4271-6836-4aa0-8642-6dfa1fba8014/97cad80d-fd51-44e6-8952-57f28c95fb2e" target="_blank">Data Privacy Requests</a>
                              </p>
                              
          </div>
          <div className="c-site-footer__center">
            {!spinner.state && (
              <h6 className="u-hide u-show-block@wide">
                Ready to make some noise?
              </h6>
            )}
            <Button
            id="spinnaButton"
              variant="solid"
              isLoading={spinner.state || task.state}
              isDisabled={spinner.state || task.state}
              onClick={() => {
                if (task.state) {
                  task.handler(false);
                }
                spinner.handler(true);
                spinner.numberHandler(spinner.number + 1);
                
                window.utag.link({"_GA_EventAction":'SpinButton',"_GA_EventCategory":'Site',"_GA_EventLabel":window.utag.data['dom.url']});
              }}
            >
              {spinner.state || task.state
                ? 'Make some noise!'
                : 'Spin the spinna!'}
              <SplashLeft id="icon-splash-left">
                <SplashLeftSVG />
              </SplashLeft>
              <SplashRight id="icon-splash-right">
                <SplashRightSVG />
              </SplashRight>
            </Button>
          </div>
          <div className="c-site-footer__right">
            <Legend />
          </div>
        </div>
      </div>
      
  </div>
    
  );
};

export default SiteFooter;

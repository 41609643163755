import React, { useContext } from 'react';
import { useMediaQuery } from 'react-responsive';

// Config
import { BREAKPOINTS } from '../config';

// Context
import SpinnaContext from '../contexts/SpinnaContext';

// Components
import Button from '../components/Button';
import Sprite from '../components/Sprite';
import Legend from '../components/Legend';
import Rooftop from '../components/Rooftop';

// Assets
import LogoDesktopSVG from '../../images/logo-desktop.svg';
import LogoMobileSVG from '../../images/logo-mobile.svg';
import { ReactComponent as FacebookSVG } from '../../icons/icon-facebook.svg';
import { ReactComponent as YoutubeSVG } from '../../icons/icon-facebook.svg';
import { ReactComponent as VolumeOnSVG } from '../../icons/icon-volume-on.svg';
import { ReactComponent as VolumeOffSVG } from '../../icons/icon-volume-off.svg';

const SiteHeader = () => {
  const context = useContext(SpinnaContext);
  const { spinner } = context;

  // Media Queries
  const isTablet = useMediaQuery({
    query: `(min-width: ${BREAKPOINTS.tablet}px)`,
  });

  return (
    
    <div className="c-site-header">
      <div className="c-site-header__top">
        <Rooftop />
      </div>
      
      <div className="o-wrapper c-site-header__wrapper">
        <div className="c-site-header__body">
          <figure className="c-site-header__left">
            <img src={isTablet ? LogoDesktopSVG : LogoMobileSVG} alt="" />
          </figure>
          <div className="c-site-header__right">
            <div className="c-site-header__share">
              <div className="c-site-header__content">
                {/* <h4 className="u-hide u-show-block@tablet">
                  <span className="u-text-color-primary">{spinner.total}</span>{' '}
                  Challenges served up so far!
                </h4> */}
                <h6>Share your fajita friday pics</h6>
                <h6 className="u-text-color-primary">#oldelpasofajitafriday</h6>
              </div>
              <div className="c-site-header__social">
                <Button
                  variant="social"
                  href={`https://www.facebook.com/sharer.php?u=${window.location.hostname}&quote=Making some noise playing Old El Paso’s Fajita Friday Spinna. Now it’s your turn!`}
                  target="_blank"
                >
                  <Sprite id="icon-facebook">
                    <FacebookSVG />
                  </Sprite>
                  
                </Button>
                &nbsp; &nbsp;
                <Button
                  variant="social"
                  href={'https://www.youtube.com/user/TheOldElPasoUK'}
                  target="_blank"
                >
                <span id="icon-youtube">
                    
                  </span>
                  </Button>
              </div>
            </div>
            <div className="u-hide@desktop c-site-header__legend">
              <Legend />
            </div>
            <p className="u-hide u-show-block@tablet u-hide@desktop u-body-sm c-site-header__footnote">
              Visit{' '}
              <a
                href="https://oldelpaso.co.uk"
                target="_blank"
                rel="noopener noreferrer"
              >
                oldelpaso.co.uk
              </a>{' '}
              to find more <br></br>Fajita Friday recipes and products!<br></br>
              {/* <span className="u-copyright">&copy; General Mills</span> */}
            </p>
            <p className="u-hide@tablet u-copyright" style={{ float: 'left' }}>
              <a className='anchor' href="https://www.generalmills.com/en/Company/privacy-policies/privacy-policy-cookie-UK" target="_blank">Privacy Policy</a>&nbsp;&nbsp;&nbsp;&nbsp;
                              <a className='anchor' href="https://contactus.generalmills.com/?page=www.generalmills.co.uk&_ga=2.238789632.124113736.1612367139-969072383.1550170555" target="_blank">Contact Us</a>&nbsp;&nbsp;&nbsp;&nbsp;
                              <a className='anchor' href="https://www.generalmills.com/en/Company/privacy-policies/privacy-policy-cookie-UK#CookieNotice" target="_blank">Cookie Notice</a>&nbsp;&nbsp;&nbsp;
                              <a className='anchor' href="https://www.generalmills.com/Company/legal-terms/termsofuse-ENUK" target="_blank">Terms of Use</a><br/>
                             
  
      
   

                              <a className='anchor optanon-show-settings'>Customise Cookie Settings</a>&nbsp;&nbsp;&nbsp;
                              <a className='anchor' href="https://privacyportal.onetrust.com/webform/3fab4271-6836-4aa0-8642-6dfa1fba8014/97cad80d-fd51-44e6-8952-57f28c95fb2e" target="_blank">Data Privacy Requests</a>
            </p>
            
            <Button
              className="u-hide@desktop c-site-header__sound"
              variant="icon"
              onClick={() => spinner.volumeHandler(spinner.volume ? 0 : 1)}
            >
              {spinner.volume !== 0 ? (
                <Sprite id="icon-volume-on">
                  <VolumeOnSVG />
                </Sprite>
              ) : (
                <Sprite id="icon-volume-off">
                  <VolumeOffSVG />
                </Sprite>
              )}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SiteHeader;
